import { useMemo } from "react";
import { WindowLocation } from "@reach/router";
import Img from "gatsby-image";

import {
  Article,
  Comment,
  Container,
  Metadata,
  PublishDate,
  Tags
} from "@/component";
import { Post } from "@/model";

import style from "./post.module.scss";

type Props = {
  post: Post;
  location: WindowLocation;
};
export function PostLayout(props: Props): JSX.Element {
  const { post, location } = props;
  const { origin, pathname } = location;
  const { title, cover, tags, updateDate, publishDate, excerpt } = post;
  const { fluid } = cover;
  const meta = useMemo(
    () => [
      {
        property: "article:published_time",
        content: new Date(publishDate).toISOString()
      },
      {
        property: "article:modified_time",
        content: new Date(updateDate).toISOString()
      }
    ],
    [publishDate, updateDate]
  );
  return (
    <>
      <Metadata
        title={title}
        description={excerpt}
        keywords={tags}
        meta={meta}
        pathname={pathname}
        image={fluid.src}
      />
      <Container>
        <Img fluid={fluid} className={style.cover} title={title} />
        <div className={style.content}>
          <h1 className={style.title}>{title}</h1>
          <PublishDate publishDate={publishDate} />
          <Tags tags={tags} />
          <Article post={post} />
        </div>
      </Container>
      <Container className={style.comment} top padding>
        <Comment origin={origin} pathname={pathname} title={title} />
      </Container>
    </>
  );
}
