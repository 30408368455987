import { useMemo } from "react";
import { WindowLocation } from "@reach/router";

import { BlogGrid, Metadata, Pagination } from "@/component";
import { SimplePost } from "@/model";

import style from "./blog.module.scss";

function getTitle(current: number): string {
  return current === 1 ? "首頁" : `第${current}頁`;
}

type Props = {
  posts: SimplePost[];
  page: {
    current: number;
    next?: string;
    prev?: string;
  };
  location: WindowLocation;
  onTitle?: (current: number) => string;
};
export function BlogLayout(props: Props): JSX.Element {
  const { posts, page, location, onTitle = getTitle } = props;
  const { pathname } = location;
  const { current, next, prev } = page;
  const title = useMemo(() => onTitle(current), [current, onTitle]);
  return (
    <>
      <Metadata title={title} pathname={pathname} />
      <BlogGrid posts={posts} />
      <Pagination next={next} prev={prev} className={style.pagination} />
    </>
  );
}
