import { SimplePost } from "@/model";
import { BlogLayout } from "@/layout";

import { PageProps } from "./type";

export type Context = {
  posts: SimplePost[];
  page: number;
  nextPage?: string;
  prevPage?: string;
};

export default function BlogPage(
  props: PageProps<Record<string, unknown>, Context>
): JSX.Element {
  const { pageContext, location } = props;
  const { posts, page: current, nextPage: next, prevPage: prev } = pageContext;
  const page = { current, next, prev };
  return <BlogLayout posts={posts} page={page} location={location} />;
}
